import React, { useState, useEffect } from "react";
import { navigate, graphql } from "gatsby";

const niceList = (array: string[]) => {
  const arr = array.slice(0);
  const last = arr.pop();
  return arr.join(", ") + ", and " + last;
};

export const Index: React.FC<any> = ({ data }) => {
  const [refreshToken, setRefreshToken] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const traktAuthUrl = `https://trakt.tv/oauth/authorize?client_id=${data.site.siteMetadata.traktId}&redirect_uri=${data.site.siteMetadata.traktRedirectUri}&response_type=code`;
  const scrobbleUrl = `${data.site.siteMetadata.traktRedirectUri}/api/scrobble?refresh_token=${refreshToken}`;

  const checkAuth = async () => {
    const url = new URL(window.location.href);

    console.log("URL:", url);

    if (url.searchParams.has("code")) {
      setIsLoading(true);
      const res = await fetch("/api/auth", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ code: url.searchParams.get("code") }),
      });

      if (!res.ok) {
        console.error(res.body);
        return;
      }

      const json = await res.json();
      setRefreshToken(json.refreshToken);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <main>
      <p>
        Trex syncs{" "}
        <a href="https://plex.tv" target="_blank">
          Plex
        </a>{" "}
        scrobbles with{" "}
        <a href="https://trakt.tv" target="_blank">
          Trakt
        </a>
        .
      </p>
      <p>
        Log in with Trakt below to receive your scrobbling URL that you can
        insert into Plex's Webhook settings.
      </p>

      {refreshToken ? (
        <input type="text" value={scrobbleUrl} readOnly />
      ) : (
        <a href={traktAuthUrl} className="login-btn">
          Log in with Trakt
        </a>
      )}
    </main>
  );
};

export const query = graphql`
  query Index {
    site {
      siteMetadata {
        traktId
        traktRedirectUri
      }
    }
  }
`;

export default Index;
